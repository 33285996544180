<template>
	<div
		class="position-fixed-full z-index-layer"
	>
		<div class="bg-layer z-index-bg"></div>
		<div
			class="position-relative flex-column justify-center full-width full-height z-index-contents"
		>
			<div class="full-height full-width position-relative ma-auto" >
				<div class="flex-column justify-center full-height" >



					<iframe
						v-if="pg_info.ui_type == 'popup'"
						:src="pg_order_url"
						class="full-height full-width"
					></iframe>
					<iframe
						v-else
						v-show="false"
						:src="pg_order_url"
						class="full-height full-width"
					></iframe>
				</div>
				<div
					class="flex-column justify-center items-center full-height color-white"
				>
					<p class="size-px-24 font-weight-700">결제가 진행중입니다</p>
					<p class="mt-10 pa-20 size-px-16">결제 화면이 보이지 않는다면 팝업 차단을 해제해주세요</p>

					<div class="mt-30">

						<button
							@click="$emit('cancel')"
							class="btn bg-black"
						><slot name="name-cancel">결제 취소</slot></button>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>


<script>

export default {
	name: 'OrderFormPg'
	, components: {}
	, props: ['Axios', 'user', 'order_info', 'member_info', 'pg_info']
	, data: function(){
		return{
			pg_order_url: ''
		}
	}
	, methods: {
		setOrder: function(){
			sessionStorage.setItem('order_info', JSON.stringify(this.order_info))
			this.getOrder()
		}
		, getOrder: function(){
			let t = sessionStorage.getItem('order_info')
			if(!t){
				this.$emit('cancel')
			}else{
				t = JSON.parse(t)
				if(t.order_number == this.order_info.order_number){
					// let w_a = window.open('/payment/allat/approval.html?on=' + this.order_info.order_number, 'allalt', 'width=320')

					let self = this
					window.addEventListener('message', function (e) {
						// console.log('on message', e.data)

						try{

							let data = JSON.parse(e.data)
							let result = data.result
							if(result){
								console.log('success');
								self.$emit('success', data)
							}else{
								console.log('fail');
								self.$emit('fail', data)
							}
							// w_a.close()
						}catch (e){
							console.log('addEventListener', e)
						}

					});
				}else{
					this.$emit('cancel')
				}
			}

		}
	}
	, created() {
		// console.log('order_info', this.order_info)

		let baseUrl = this.$baseUrl

		this.pg_order_url = baseUrl + '/Order/postPg?P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT') + '&order_number=' + this.order_info.order_number

		this.setOrder()
	}
}

</script>


<style>
.position-fixed-full {
	position: fixed; left: 0; top: 0; width: 100%; height: 100%;
}
.z-index-layer {
	z-index: 9999;
}
.z-index-contents {
	z-index: 9998;
}
.z-index-bg {
	z-index: 9997;
}

.bg-layer {
	position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.5; background-color: black;
}

iframe { overflow: scroll}
</style>
